import _ from 'lodash';

import { CART_ORDER_SOURCE, ORDER_STATUS } from '@/config/common';
import { itemMediaListMapper } from '@/helpers/carousel';
import * as HttpService from '@/services/http.service';
import { GET_ORDER_DETAIL_API_URL } from '@/services/url.service';

export const getTransformedProductMediaList = ({
  orderItemMedia,
  productMedia,
  showProductMedia
}) => {
  const resetImageTitle = (image) => ({ ...image, imageTitle: '' });

  const productMediaList = itemMediaListMapper({
    additionalMediaList: orderItemMedia,
    product: { productMedia },
    showProductMedia,
    source: CART_ORDER_SOURCE.ORDER
  }).itemMediaForCarousel.map(resetImageTitle);

  return productMediaList;
};

export const getStatusBasedClass = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        orderStatusSpecificClassName: 'bg-dim-gray'
      };
      break;
    default:
      dictionary = {
        orderStatusSpecificClassName: 'bg-brand-gradient'
      };
      break;
  }
  return dictionary;
};

export const getStatusBasedAttributes = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        dateIcon: 'calendar-gray.svg',
        deliveryIcon: 'delivery-truck-sliver.svg',
        dividerClass: 'border-silver',
        grandTotalClass: 'bg-dim-gray',
        locationIcon: 'location-icon-gray.svg',
        orderNumberClass: 'bg-dim-gray',
        orderStatusSpecificClassName: 'cancelled-order',
        pickupIcon: 'pickup-truck-sliver.svg',
        rightArrowIcon: 'right-angle-gray-bg.svg',
        themeDecor: 'theme-decor-gray.svg'
      };
      break;
    default:
      dictionary = {
        dateIcon: 'calendar-red-with-frame.svg',
        deliveryIcon: 'delivery-truck.svg',
        dividerClass: 'border-dim-gray',
        grandTotalClass: 'bg-olive-black',
        locationIcon: 'location-icon-with-frame.svg',
        orderNumberClass: 'bg-planner-cart-bg',
        pickupIcon: 'pickup-truck.svg',
        rightArrowIcon: 'right-angle-red-bg.svg',
        themeDecor: 'theme-decor.svg'
      };

      break;
  }
  return dictionary;
};

const getOrdersDetailAPI = ({ partnerId, orderId }) =>
  HttpService.getWithAuth(GET_ORDER_DETAIL_API_URL({ partnerId, orderId }));

export const fetchOrderDetails = async ({
  orderId,
  partnerUser,
  setLoading,
  setOrderDetails,
  setShowToast
}) => {
  setLoading(true);
  try {
    const { entity, status, message } = await getOrdersDetailAPI({
      partnerId: partnerUser.partnerOrgId,
      orderId
    });
    if (status) {
      const {
        deliveryDate,
        deliveryTime,
        eventDate,
        eventLocation,
        eventName,
        orderItemAmount,
        orderNumber,
        partnerDeliveryCharge,
        partnerOrderTotal,
        pickupDate,
        pickupTime,
        plannerName,
        preVAT,
        showDeliveryInfo,
        VAT,
        vatApplicable
      } = entity;
      const orderDetailsInfo = {
        eventInfo: {
          eventDate,
          eventLocation,
          eventName,
          orderNumber,
          plannerName
        },
        orderDelivery: {
          deliveryDate,
          deliveryTime,
          pickupDate,
          pickupTime,
          showDeliveryInfo
        },
        orderSummary: {
          orderItemAmount,
          partnerDeliveryCharge,
          partnerOrderTotal,
          preVAT,
          VAT,
          vatApplicable
        }
      };
      setOrderDetails({
        ...entity,
        ..._.pick(orderDetailsInfo, [
          'eventInfo',
          'orderDelivery',
          'orderSummary'
        ])
      });
    }
    !status && setShowToast({ message, show: true, toastFlag: status });
  } catch (error) {
    setShowToast({
      message: error.message || 'Failed to fetch API info',
      show: true,
      toastFlag: false
    });
  } finally {
    setLoading(false);
  }
};
